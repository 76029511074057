.main {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 4px;
  width: min(500px, calc(100vw - 50px));
  position: relative;
}

.title {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 1rem 0 2rem;
  text-align: center;
}

.closeButton {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: none;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  background: none;
  transition: color .2s;
  position: absolute;
  top: 4px;
  right: 4px
}

.closeButton:hover {
  cursor: pointer;
  color: #000;
}
