.d-none{
	display: none !important;
}

.d-block{
	display: inline-block !important;
}
button.btn-request {
    border: none;
}

button.btn-request:disabled {
    opacity: 0.5;
    background-image: none;
    background-color: #999999;
    cursor: default;
}

#catalog .form-action-box {
    display: flex;
}

#catalog .catalog {
    margin:0 25px;
    font-weight: normal;
}

#catalog .catalog h2 {
    font-size: 2rem;
    margin: 40px auto 1rem;
    text-align: center;
}

#catalog .catalog h2, #catalog .catalog h3, #catalog .catalog h4 {
    font-weight: normal;
    line-height: 1.2;
}

#catalog .catalog h4 {
    font-size: 1rem;
    font-weight: 600;
}

#catalog .catalog h3 {
    font-size: 1.5rem;
}

#catalog .catalog header {
    position: static;
}

#catalog .catalog header h3, #catalog .catalog .right-header h3 {
    margin: 15px 0 10px 10px;
}

#catalog .catalog .product .product-content .right-header {
    display: none;
}

.products {
    display: table;
    width: 100%;
    border-spacing:10px;
}

.product {
    display: table-cell;
    border: 1px solid #ddd;
    padding: 10px;
    width: 50%;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.product>div:nth-child(1) {
    width: calc(100% - 35px);
}

.product:nth-child(2) {
    margin-left: 3px;
}

p.note-face-device {
    margin-bottom: 0;
    font-size: .8rem;
    line-height: 1.2;
    color: #999999;
}

.product p.note-face-device {
    margin-left: 10px;
}

.product header p, .product .right-header p {
    margin: -10px 0 0 10px;
    color: #999999;
    font-size: 1rem;
}

.product-content {
    display: table;
}

.description-sec, .photo-sec {
    vertical-align: top;
    display: table-cell;
}

.description-sec {
    width: 100%;
    margin: 10px;
}

.photo-sec {
    margin: 10px 0;
}

.description-sec li {
    list-style-type: none;
    padding-left: 17px;
}

.description-sec li:before {
    content: "-";
    position: absolute;
    margin-left: -15px;
}

#catalog .catalog .photo-sec .thumbnail {
    border: none;
    padding: 0;
    cursor: pointer;
}

.pay-sec {
    margin: 10px;
    display: grid;
    grid-template-columns: 140px 240px 240px;
}


.btn-pay, .btn-request {
    display: inline-block;
    width: 200px;
    border-radius: 90px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
    line-height: 1.2;
    box-sizing: unset;
    background-image: linear-gradient(to right, #09b3ef 0%, #1e50e2 51%, #09b3ef 100%);
    background-size: 200% auto;
    transition: background .2s;
}

.btn-request{
	/* font-size: 16px; */
}
.btn-pay:hover, .btn-request:hover {
    color:#ffffff;
    background-position: right center;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.pay-sec .price {
    font-size: 1.5rem;
    line-height: 43px;
    margin-right: 5px;
    width: 150px;
    display: inline-block;
}

.catalog .related-products {
    margin: 20px 0 0 0;
}

.catalog .related-products h3 {
    margin: 30px 4px 5px;
}

#catalog .catalog .hide {
    display: none;
}

.vertical-note {
    width: 57px;
    margin-top: 50px;
    height: 375px;
    position: absolute;
    top: 0;
    right: 0;
}
.vertical-note p {
    text-align: center;
    writing-mode: vertical-rl;
    transform:rotate(180deg);
    color: #999999;
    font-size: 0.8rem;
    line-height: 1.2;
    margin: 0 0 0 10px;
}

.product .product-content .photo-sec .react-fancybox .thumbnail img {
  width: 180px;
  cursor: pointer;
}

.react-fancybox .box {
    z-index: 1000;
}

.react-fancybox .box .image-box {
    margin: 0 15px;
}

.react-fancybox .image-box img {
    max-width: 85vw;
    width: auto;
}

.attached-goods {
    margin-top: 5px;
    margin-left: 10px;
}

.catalog .highlighted-information {
    color: #669900;
    font-weight: 600;
}

.catalog .highlighted-interaction {
    color: #0000ee;
    cursor: pointer;
    font-weight: 600;
}

.catalog .highlighted-interaction:hover {
    text-decoration-line: underline
}

/* stylized-checkbox */
.stylized-checkbox input[type=checkbox] {
  display: none;
}

.stylized-checkbox .pseudocheckbox::before {   
    content: "\00A0";
    display: inline-block;
    box-sizing: border-box;
    width: 20px; 
    height: 20px;
    background-color: white;
    border: 2px solid #555;
    border-radius: 2px;
    margin-right: 6px;
    vertical-align: baseline;
    
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #555;
}

.stylized-checkbox input[type=checkbox]:checked + .pseudocheckbox::before {
  content: "\2713";
}

.stylized-checkbox input[type=checkbox]:disabled + .pseudocheckbox::before {
  color: #aaa;
  border: 2px solid #aaa;
}
/* /stylized-checkbox */

@media screen and (max-width: 1200px) {
    .products {
        display: block;
    }
    
    .product {
        display: block;
        margin: 4px;
        width: auto;
    }

    .product:nth-child(1) {
        margin-right: 4px;
    }

    .product:nth-child(2) {
        margin-left: 4px;
    }
}

@media screen and (max-width: 768px) {
    .catalog .products .product .product-content .photo-sec {
        display: table-header-group;
    }
    #catalog .catalog .products .product .product-content .photo-sec .right-header,
    #catalog .catalog .products .product .product-content .photo-sec .react-fancybox:nth-child(1) {
        display: inline-block;
        vertical-align: top;
    }

    .product header {
        display: none;
    }
    .catalog .product>div>div.pay-sec {
        display: none;
    }
    #catalog .catalog .product .product-content .right-header {
        display: block;
    }
    .pay-sec {
        grid-template-rows: 2em 43px 43px;
        row-gap: 17px;
        grid-template-columns: none;
    }
}

@media screen and (max-width: 550px) {
    html {
        font-size: 15px;
    }
    .product .product-content .photo-sec .react-fancybox .thumbnail img {
        max-width: 110px;
    }
    #catalog .description-sec .btn-pay, #catalog .product .right-header .btn-pay {
        font-size: .9rem;
        width: 160px;
    }
    .catalog .related-products h3 {
        margin: 5px 15px;
    }
    #catalog .catalog {
        margin: 0 0px;
    }
    .pay-sec {
        grid-template-rows: 3em 37px 37px;
    }
}

@media screen and (max-width: 480px) {
    #catalog .product .product-content .photo-sec .react-fancybox .thumbnail img {
        max-width: 100%;
    }
}

@media screen and (max-width: 350px) {
    #catalog .product .vertical-note {
        width: 30px;
    }
    #catalog .product .vertical-note p {
        margin: 0; 
    }
}

/* related-products */

.related-products .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: stretch;
}

@media screen and (max-width: 1050px) {
    .related-products .blocks {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 860px) {
    .related-products .blocks {
        grid-template-columns: 1fr;
    }
}

.related-product {
    border: 1px solid #ccc;
    padding: 10px; 
}
.related-product-main {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100px 1fr;
    height: 100%;
}

.related-product-main img {
    width: 100%;
}

.description-related-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description-related-product p.note-face-device {
    margin-top: 7px;
    font-size: 0.7rem;
    line-height: 1.1;
}

.pay-related-product {
    display: block;  
}

.pay-related-product .btn-pay {
    width: auto;
    padding: 4px 17px;
    display: inline-block;
    white-space: nowrap;
    font-size: 1rem;
    margin-top: 10px;
}

.pay-related-product .btn-request {
		width: auto;
    padding: 4px 17px;
    display: inline-block;
    white-space: nowrap;
    font-size: 1rem;
    margin-top: 10px;
}

.pay-related-product .price {
    min-width: 95px;
    display: inline-block;
    font-weight: 600;
    font-size: 1.2rem;
}

.related-product.showAll {
    text-align: center;
    cursor: pointer;
}

.related-product.showAll img {
    width: 100px;
}

#catalog .catalog .related-product-main h4 {
    margin: 5px 0;
    color: #444;
}


.react-fancybox:nth-child(2) {
	display: none ;
}

/* /related-products */