.modalContent {
    font-weight: normal;
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
}

.modalContent h2.title {
    font-size: 1.25rem;
    font-weight: 400;
}

.modalContent header {
    position: static;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px 0;
    font-size: 1.5rem;
}

.modalContent header span {
    flex-basis: 100%;
    margin: 0 20px 0 0;
    font-weight: normal;
    color: #333333;
}

.modalContent header img {
    height: 20px;
    margin: 0 20px 0 0;
    display: inline-block;
}

.modalContent header .btn-close {
    cursor: pointer;
    color: #999;
    position: relative;
    font-size: 2rem;
    font-weight: normal;
    cursor: pointer;
    right: -10px;
    top: -15px;
    line-height: 1;
}

.modalContent header .btn-close:hover {
    color: #333;
}

.product-sec {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    align-items: flex-start;
}

.modalContent .product-sec>img {
    width: auto;
    height: 100px;
    margin-right: 10px;
}

.modalContent .product-sec div.info {
    width: 100%;
    padding-right: 10px;
    font-size: 1rem;
}

.modalContent .product-sec div.info div:nth-child(1) {
    
}

.modalContent .title-product-item {
    font-weight: normal;
    font-size: 1rem;
    color: #999;
    margin: 10px 0 0 0;
}

.modalContent .product-item {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 7px 0;
    font-size: 1rem;
}

.modalContent .product-item .name {
    flex-basis: 100%;
    font-size: 1rem;
    margin: 5px 10px 0 0;
}

.modalContent .product-item .price {
    font-size: 1rem;
    margin: 5px 15px 0 0;
    white-space: nowrap;
    width: 135px;
}

.modalContent .delete-product {
    cursor: pointer;
    color: #999;
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 2px;
}

.modalContent .delete-product:hover {
    color: #000;
}

.modalContent .control {
    display: flex;
    justify-content: space-between;
    padding: 18px 0 7px;
    min-width: 180px;
}

.modalContent .control .btn {
    cursor: pointer;
    font-weight: normal;
    font-size: 1rem;
    color: #333333;
    text-align: center;
    padding: 7px;
    border: 1px solid #333;
    border-radius: 3px;
    white-space: normal;
}

.modalContent .control .btn:hover {
    background-color: #333333;
    color: #fff;
}

.modalContent .control .btn:nth-child(1) {
    margin-right: 20px;
}

.modalContent .control .btn:nth-child(2) {
    background-image: linear-gradient(to right, #09b3ef 0%, #1e50e2 51%, #09b3ef 100%);
    background-size: 200% auto;
    color: #fff;
    border: none;
}

.modalContent .control .btn:nth-child(2):hover {
    color:#ffffff;
    background-position: right center;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.ReactModalPortal .hide {
    display: none;
}

.modalContent .counter-section {
    margin-top: 6px;
}
.modalContent .counter {
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 75px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  height: auto;
  min-height: 20px;
  box-sizing: content-box;
}

.modalContent .counter>div {
  display: inline-block;
}

.modalContent input.count {
  width: 30px;
  text-align: center;
  border: none;
  display: block;
  height: 20px;
  font-size: 16px;
  outline: 0;
  outline-offset: 0;
  background-color: rgba(0,0,0,0);
  padding: 0;
  line-height: 1;
  color: #333;
}

.modalContent .left-btn, .right-btn {
  width: 20px;
  text-align: center;
  cursor: pointer;
  line-height: 1.2;
  border-radius: 15px;
  font-size: 16px;
}

.modalContent .left-btn {
    line-height: 1;
}

.modalContent .left-btn:hover, .right-btn:hover {
    background-image: linear-gradient(to right, #09b3ef 0%, #1e50e2 51%, #09b3ef 100%);
    background-size: 200% auto;
}

.modalContent .counter-preloader {
    width: 76px;
    position: absolute;
    background: rgba(255,255,255,.6);
    border-radius: 15px;
}

.modalContent .counter-preloader img.preloader-img {
    height: 22px;
    margin: 0 auto;
    display: block;
    border: none;
}

@media screen and (max-width: 480px) {
    html {
        font-size: 15px;
    }
    .modalContent .product-sec>img {
        width: 75px;
        height: auto;
    }
}

@media screen and (max-width: 415px) {
    .modalContent .product-item {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .modalContent .product-item .name {
        flex-basis: 50%;
    }
    .modalContent .product-item .price {
        flex-basis: 30%;
    }
    .modalContent .product-item .delete-product {
        width: 10px;
    }
    .modalContent .product-item .counter-section {
        order: 3;
    }
}
