.formInput {
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-bottom: 1rem;
  align-items: center;
}

.formInput input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #000000;
}

.formInput label {
  margin: 0;
  color: #000000;
}

.formInput.error input {
  border-color: red;
}