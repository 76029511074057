body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

html {
    font-size: 16px;
}

.modalContent {
    font-weight: normal;
    background-color: #fff;
    padding: 15px;
    border-radius: 4px;
}

.modalContent h2.title {
    font-size: 1.25rem;
    font-weight: 400;
}

.modalContent header {
    position: static;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 0 0 15px 0;
    font-size: 1.5rem;
}

.modalContent header span {
    flex-basis: 100%;
    margin: 0 20px 0 0;
    font-weight: normal;
    color: #333333;
}

.modalContent header img {
    height: 20px;
    margin: 0 20px 0 0;
    display: inline-block;
}

.modalContent header .btn-close {
    cursor: pointer;
    color: #999;
    position: relative;
    font-size: 2rem;
    font-weight: normal;
    cursor: pointer;
    right: -10px;
    top: -15px;
    line-height: 1;
}

.modalContent header .btn-close:hover {
    color: #333;
}

.product-sec {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 15px 0;
    align-items: flex-start;
}

.modalContent .product-sec>img {
    width: auto;
    height: 100px;
    margin-right: 10px;
}

.modalContent .product-sec div.info {
    width: 100%;
    padding-right: 10px;
    font-size: 1rem;
}

.modalContent .product-sec div.info div:nth-child(1) {
    
}

.modalContent .title-product-item {
    font-weight: normal;
    font-size: 1rem;
    color: #999;
    margin: 10px 0 0 0;
}

.modalContent .product-item {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding: 7px 0;
    font-size: 1rem;
}

.modalContent .product-item .name {
    flex-basis: 100%;
    font-size: 1rem;
    margin: 5px 10px 0 0;
}

.modalContent .product-item .price {
    font-size: 1rem;
    margin: 5px 15px 0 0;
    white-space: nowrap;
    width: 135px;
}

.modalContent .delete-product {
    cursor: pointer;
    color: #999;
    font-size: 1.5rem;
    line-height: 1;
    margin-top: 2px;
}

.modalContent .delete-product:hover {
    color: #000;
}

.modalContent .control {
    display: flex;
    justify-content: space-between;
    padding: 18px 0 7px;
    min-width: 180px;
}

.modalContent .control .btn {
    cursor: pointer;
    font-weight: normal;
    font-size: 1rem;
    color: #333333;
    text-align: center;
    padding: 7px;
    border: 1px solid #333;
    border-radius: 3px;
    white-space: normal;
}

.modalContent .control .btn:hover {
    background-color: #333333;
    color: #fff;
}

.modalContent .control .btn:nth-child(1) {
    margin-right: 20px;
}

.modalContent .control .btn:nth-child(2) {
    background-image: linear-gradient(to right, #09b3ef 0%, #1e50e2 51%, #09b3ef 100%);
    background-size: 200% auto;
    color: #fff;
    border: none;
}

.modalContent .control .btn:nth-child(2):hover {
    color:#ffffff;
    background-position: right center;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.ReactModalPortal .hide {
    display: none;
}

.modalContent .counter-section {
    margin-top: 6px;
}
.modalContent .counter {
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 75px;
  display: flex;
  justify-content: space-between;
  margin-right: 10px;
  height: auto;
  min-height: 20px;
  box-sizing: content-box;
}

.modalContent .counter>div {
  display: inline-block;
}

.modalContent input.count {
  width: 30px;
  text-align: center;
  border: none;
  display: block;
  height: 20px;
  font-size: 16px;
  outline: 0;
  outline-offset: 0;
  background-color: rgba(0,0,0,0);
  padding: 0;
  line-height: 1;
  color: #333;
}

.modalContent .left-btn, .right-btn {
  width: 20px;
  text-align: center;
  cursor: pointer;
  line-height: 1.2;
  border-radius: 15px;
  font-size: 16px;
}

.modalContent .left-btn {
    line-height: 1;
}

.modalContent .left-btn:hover, .right-btn:hover {
    background-image: linear-gradient(to right, #09b3ef 0%, #1e50e2 51%, #09b3ef 100%);
    background-size: 200% auto;
}

.modalContent .counter-preloader {
    width: 76px;
    position: absolute;
    background: rgba(255,255,255,.6);
    border-radius: 15px;
}

.modalContent .counter-preloader img.preloader-img {
    height: 22px;
    margin: 0 auto;
    display: block;
    border: none;
}

@media screen and (max-width: 480px) {
    html {
        font-size: 15px;
    }
    .modalContent .product-sec>img {
        width: 75px;
        height: auto;
    }
}

@media screen and (max-width: 415px) {
    .modalContent .product-item {
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .modalContent .product-item .name {
        flex-basis: 50%;
    }
    .modalContent .product-item .price {
        flex-basis: 30%;
    }
    .modalContent .product-item .delete-product {
        width: 10px;
    }
    .modalContent .product-item .counter-section {
        order: 3;
    }
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__title {
    /*background-color: #f4f4f4;*/
    color: #444;
    cursor: pointer;
    /*padding: 18px;*/
    padding: 0 0 10px 0;
    width: 100%;
    text-align: left;
    border: none;
}

.accordion__title h4 {
    margin: 10px 0 0 10px;
    display: flex;
    justify-content: space-between;
}

.accordion__title:focus {
    outline: none;
}

.accordion__title:hover {
    /*background-color: #ddd;*/
}

.accordion__body {
    padding: 0 10px 10px 10px;
    display: block;
    animation: fadein 0.35s ease-in;
    line-height: 1.2rem;
    font-size: 1rem;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    margin: 3px 6px 0 0;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
}

.accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}

.d-none{
	display: none !important;
}

.d-block{
	display: inline-block !important;
}
button.btn-request {
    border: none;
}

button.btn-request:disabled {
    opacity: 0.5;
    background-image: none;
    background-color: #999999;
    cursor: default;
}

#catalog .form-action-box {
    display: flex;
}

#catalog .catalog {
    margin:0 25px;
    font-weight: normal;
}

#catalog .catalog h2 {
    font-size: 2rem;
    margin: 40px auto 1rem;
    text-align: center;
}

#catalog .catalog h2, #catalog .catalog h3, #catalog .catalog h4 {
    font-weight: normal;
    line-height: 1.2;
}

#catalog .catalog h4 {
    font-size: 1rem;
    font-weight: 600;
}

#catalog .catalog h3 {
    font-size: 1.5rem;
}

#catalog .catalog header {
    position: static;
}

#catalog .catalog header h3, #catalog .catalog .right-header h3 {
    margin: 15px 0 10px 10px;
}

#catalog .catalog .product .product-content .right-header {
    display: none;
}

.products {
    display: table;
    width: 100%;
    border-spacing:10px;
}

.product {
    display: table-cell;
    border: 1px solid #ddd;
    padding: 10px;
    width: 50%;
    position: relative;
    box-sizing: border-box;
}

.product>div:nth-child(1) {
    width: calc(100% - 35px);
}

.product:nth-child(2) {
    margin-left: 3px;
}

p.note-face-device {
    margin-bottom: 0;
    font-size: .8rem;
    line-height: 1.2;
    color: #999999;
}

.product p.note-face-device {
    margin-left: 10px;
}

.product header p, .product .right-header p {
    margin: -10px 0 0 10px;
    color: #999999;
    font-size: 1rem;
}

.product-content {
    display: table;
}

.description-sec, .photo-sec {
    vertical-align: top;
    display: table-cell;
}

.description-sec {
    width: 100%;
    margin: 10px;
}

.photo-sec {
    margin: 10px 0;
}

.description-sec li {
    list-style-type: none;
    padding-left: 17px;
}

.description-sec li:before {
    content: "-";
    position: absolute;
    margin-left: -15px;
}

#catalog .catalog .photo-sec .thumbnail {
    border: none;
    padding: 0;
    cursor: pointer;
}

.pay-sec {
    margin: 10px;
    display: grid;
    grid-template-columns: 140px 240px 240px;
}


.btn-pay, .btn-request {
    display: inline-block;
    width: 200px;
    border-radius: 90px;
    cursor: pointer;
    text-align: center;
    padding: 10px;
    color: #fff;
    font-size: 1.2rem;
    text-decoration: none;
    line-height: 1.2;
    box-sizing: unset;
    background-image: linear-gradient(to right, #09b3ef 0%, #1e50e2 51%, #09b3ef 100%);
    background-size: 200% auto;
    transition: background .2s;
}

.btn-request{
	/* font-size: 16px; */
}
.btn-pay:hover, .btn-request:hover {
    color:#ffffff;
    background-position: right center;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1);
}

.pay-sec .price {
    font-size: 1.5rem;
    line-height: 43px;
    margin-right: 5px;
    width: 150px;
    display: inline-block;
}

.catalog .related-products {
    margin: 20px 0 0 0;
}

.catalog .related-products h3 {
    margin: 30px 4px 5px;
}

#catalog .catalog .hide {
    display: none;
}

.vertical-note {
    width: 57px;
    margin-top: 50px;
    height: 375px;
    position: absolute;
    top: 0;
    right: 0;
}
.vertical-note p {
    text-align: center;
    writing-mode: vertical-rl;
    transform:rotate(180deg);
    color: #999999;
    font-size: 0.8rem;
    line-height: 1.2;
    margin: 0 0 0 10px;
}

.product .product-content .photo-sec .react-fancybox .thumbnail img {
  width: 180px;
  cursor: pointer;
}

.react-fancybox .box {
    z-index: 1000;
}

.react-fancybox .box .image-box {
    margin: 0 15px;
}

.react-fancybox .image-box img {
    max-width: 85vw;
    width: auto;
}

.attached-goods {
    margin-top: 5px;
    margin-left: 10px;
}

.catalog .highlighted-information {
    color: #669900;
    font-weight: 600;
}

.catalog .highlighted-interaction {
    color: #0000ee;
    cursor: pointer;
    font-weight: 600;
}

.catalog .highlighted-interaction:hover {
    -webkit-text-decoration-line: underline;
            text-decoration-line: underline
}

/* stylized-checkbox */
.stylized-checkbox input[type=checkbox] {
  display: none;
}

.stylized-checkbox .pseudocheckbox::before {   
    content: "\A0";
    display: inline-block;
    box-sizing: border-box;
    width: 20px; 
    height: 20px;
    background-color: white;
    border: 2px solid #555;
    border-radius: 2px;
    margin-right: 6px;
    vertical-align: baseline;
    
    text-align: center;
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 16px;
    font-weight: bold;
    color: #555;
}

.stylized-checkbox input[type=checkbox]:checked + .pseudocheckbox::before {
  content: "\2713";
}

.stylized-checkbox input[type=checkbox]:disabled + .pseudocheckbox::before {
  color: #aaa;
  border: 2px solid #aaa;
}
/* /stylized-checkbox */

@media screen and (max-width: 1200px) {
    .products {
        display: block;
    }
    
    .product {
        display: block;
        margin: 4px;
        width: auto;
    }

    .product:nth-child(1) {
        margin-right: 4px;
    }

    .product:nth-child(2) {
        margin-left: 4px;
    }
}

@media screen and (max-width: 768px) {
    .catalog .products .product .product-content .photo-sec {
        display: table-header-group;
    }
    #catalog .catalog .products .product .product-content .photo-sec .right-header,
    #catalog .catalog .products .product .product-content .photo-sec .react-fancybox:nth-child(1) {
        display: inline-block;
        vertical-align: top;
    }

    .product header {
        display: none;
    }
    .catalog .product>div>div.pay-sec {
        display: none;
    }
    #catalog .catalog .product .product-content .right-header {
        display: block;
    }
    .pay-sec {
        grid-template-rows: 2em 43px 43px;
        grid-row-gap: 17px;
        row-gap: 17px;
        grid-template-columns: none;
    }
}

@media screen and (max-width: 550px) {
    html {
        font-size: 15px;
    }
    .product .product-content .photo-sec .react-fancybox .thumbnail img {
        max-width: 110px;
    }
    #catalog .description-sec .btn-pay, #catalog .product .right-header .btn-pay {
        font-size: .9rem;
        width: 160px;
    }
    .catalog .related-products h3 {
        margin: 5px 15px;
    }
    #catalog .catalog {
        margin: 0 0px;
    }
    .pay-sec {
        grid-template-rows: 3em 37px 37px;
    }
}

@media screen and (max-width: 480px) {
    #catalog .product .product-content .photo-sec .react-fancybox .thumbnail img {
        max-width: 100%;
    }
}

@media screen and (max-width: 350px) {
    #catalog .product .vertical-note {
        width: 30px;
    }
    #catalog .product .vertical-note p {
        margin: 0; 
    }
}

/* related-products */

.related-products .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: stretch;
}

@media screen and (max-width: 1050px) {
    .related-products .blocks {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 860px) {
    .related-products .blocks {
        grid-template-columns: 1fr;
    }
}

.related-product {
    border: 1px solid #ccc;
    padding: 10px; 
}
.related-product-main {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 100px 1fr;
    height: 100%;
}

.related-product-main img {
    width: 100%;
}

.description-related-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description-related-product p.note-face-device {
    margin-top: 7px;
    font-size: 0.7rem;
    line-height: 1.1;
}

.pay-related-product {
    display: block;  
}

.pay-related-product .btn-pay {
    width: auto;
    padding: 4px 17px;
    display: inline-block;
    white-space: nowrap;
    font-size: 1rem;
    margin-top: 10px;
}

.pay-related-product .btn-request {
		width: auto;
    padding: 4px 17px;
    display: inline-block;
    white-space: nowrap;
    font-size: 1rem;
    margin-top: 10px;
}

.pay-related-product .price {
    min-width: 95px;
    display: inline-block;
    font-weight: 600;
    font-size: 1.2rem;
}

.related-product.showAll {
    text-align: center;
    cursor: pointer;
}

.related-product.showAll img {
    width: 100px;
}

#catalog .catalog .related-product-main h4 {
    margin: 5px 0;
    color: #444;
}


.react-fancybox:nth-child(2) {
	display: none ;
}

/* /related-products */
.input_formInput__2KZ1B {
  display: grid;
  grid-template-columns: 80px 1fr;
  margin-bottom: 1rem;
  align-items: center;
}

.input_formInput__2KZ1B input {
  padding: 8px;
  border-radius: 8px;
  border: 1px solid #ccc;
  color: #000000;
}

.input_formInput__2KZ1B label {
  margin: 0;
  color: #000000;
}

.input_formInput__2KZ1B.input_error__nCcZc input {
  border-color: red;
}
.ModalWindow_main__VhTRh {
  background-color: #ffffff;
  padding: 24px;
  border-radius: 4px;
  width: min(500px, calc(100vw - 50px));
  position: relative;
}

.ModalWindow_title__3M09C {
  font-size: 1.25rem;
  font-weight: 400;
  margin: 1rem 0 2rem;
  text-align: center;
}

.ModalWindow_closeButton__nzinK {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  border: none;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  background: none;
  transition: color .2s;
  position: absolute;
  top: 4px;
  right: 4px
}

.ModalWindow_closeButton__nzinK:hover {
  cursor: pointer;
  color: #000;
}

.PriceFeedbackDialogForm_actionBox__1qQIH {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.PriceFeedbackDialogForm_successMessage__2qAq5, .PriceFeedbackDialogForm_errorMessage__2WqCW {
  margin-top: 2rem;
  font-size: 1.25rem;
}
.PriceFeedbackDialogForm_successMessage__2qAq5 {
  color: green;
}

.PriceFeedbackDialogForm_errorMessage__2WqCW {
  color: red;
}
