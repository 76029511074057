.actionBox {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
}

.successMessage, .errorMessage {
  margin-top: 2rem;
  font-size: 1.25rem;
}
.successMessage {
  color: green;
}

.errorMessage {
  color: red;
}